$primary: #00cab0; /* MAIN COLOR */
$secondary: red; /* SECONDARY COLOR */
$color2: #da9e2e;
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa; 
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;

html,body {
	height: 100%;
}

@import url('https://fonts.googleapis.com/css?family=Roboto:500,700');

h1 {
	font-family: 'Roboto', sans-serif;
	font-weight: 700;
	color: $wht;
}


h2, h3 {
	font-family: 'Roboto', sans-serif;
	font-weight: 700;
	color: $blk;
}

.flash {
	display:none;
}

nav {
	z-index: 1000;
}

.navbar .navbar-nav {
    > li > a {
		text-align: center;
		color: #fff;
		margin-top: 25px;

		@media (max-width: 767px) {
			margin-top: 4px;
		}

	    &:hover {
	    	background: rgba(0, 202, 176, 0.6);
	    	color: #eee !important;
	    	border-radius: 5px;
	    }

	   	&:visited,&:focus,&:active {
		  color: #fff;
		  outline: 0;
	    }
	}
}

.navbar-right {
	margin-top: 0px;
}
 
.navbar-toggle {
    margin: 30px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
	
	@media (max-width: 767px) {
		margin-top: 20px;
	}
}


/** LOGIN FORM **/

@mixin btn1 {
	text-align: center;
	margin: 0 auto;
	border: 1px solid $primary;
	border-radius: $border-radius;
	background: $primary;
	color: $wht;
	padding: 1em 3em;
	margin: 20px 0;

	&:hover {
		background: lighten($primary,10%);
		color: lighten($wht, 10%);
		box-shadow: 0px 0px 3px lighten($blk,10%);
	}
}

@mixin btn2 {
	@include btn1;
	background: darken($wht, 5%);
	color: $primary;
	border: none;

	&:hover {
		background: $wht;
		color: $primary;
		border: none;
	}
}

.modal-dialog {
	max-width: 300px;
	text-align: center;
	margin: 6em auto;
	
	.close {display: none;}

	.modal-content {
		color: $primary;
		
		h2 {
			text-align:center;
		}
	}

	input {
		border: none;
		border-bottom: 1px solid darken($primary,20%);
		text-align: center;
	}
	
	button {
		background: transparent;
		color: $wht;
		display: block;
		border:none;
		padding: 1em 2em;
		margin: 0 auto;

		&:hover {
			background: transparent;
			color: #fff;
			box-shadow: none;
			text-shadow: 0px 0px 3px $blk;
			border:none;
		}
	}
	
	input[type="submit"] {
		@include btn1;
		width: 50%;
		display: inline-block;	
	}

}

.modal-header, .modal-footer {
	background: $primary;
	color: $wht;
}

input#username {
	margin-bottom: 20px;
}

/** END LOGIN FORM **/

footer {
	padding: 40px 0px 20px;
	background: $primary;
	color: $footerLinks;
	text-decoration: none;
	outline: 0;

	a {
		color: $footerLinks;
		text-decoration: none;

	&:hover,&:focus,&:visited,&:active {
	    	color: $footerLinks;
	    	text-decoration: none;
	    	outline: 0;
	    }
	}
}


input#username, input#password {
	width: 100%;
}

@media (max-width: 1024) {
	input#username, input#password {
  		font-size: 16px;
	}
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}

.banner {
	background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(../img/banner.jpg);
    background-repeat: no-repeat;
	// background: url(../img/banner.jpg) no-repeat;
	background-size: cover;
	height: 700px;
	position: relative;
	@media (max-width: 991px) {
		background-position: 16% 50%;
	}
	h1 {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 70%;
		text-align: center;
		transform: translate(-50%, -50%);
		color: white;
		font-family: 'Roboto', sans-serif;
		font-weight: 700;
		font-size: 6em;
		@media (max-width: 991px) {
			font-size: 4em;
		}
		@media (max-width: 767px) {
			font-size: 3.2em;
		}

	}
}
.navbar {
	margin-bottom: 0;
}
.box {
	width: 70%;
	margin: 0 auto;
	top: -100px;
	position: relative;
	.boxTxt {
		padding: 2em;
	}
	@media (max-width: 1199px) {
		width: 80%;
	}
}
.box1.noPad, .box2.noPad, .box3.noPad, .row.noPad {
	padding: 0;
	margin: 0;
}

.box1 {
	border-top: 3px solid $primary;
	background: white;
	border-bottom: 3px solid $primary;
	.boxTxt {
		h1 {
			color: $primary;
			font-family: 'Roboto', sans-serif;
			font-weight: 700;
			text-transform: uppercase;
		}
		p {
			font-family: 'Roboto', sans-serif;
		}
	}
	.box1Img {
		background: url('../img/couple1.jpg') no-repeat;
		background-position: 8% 30%;
		background-size: cover;
		height: 300px;
	}
	@media (max-width: 991px) {
		top: 0;
		border-bottom: none;
	}

}

.box2 {
	border-top: 3px solid $primary;
	position: relative;
	background: white;
	top: -50px;
	.box2Img {
		background: url('../img/couples.jpg') no-repeat;
		background-size: cover;
		background-position: 10% 50%;
		height: 400px;
	}
	@media (max-width: 991px) {
		top: 0;
		// border-bottom: 3px solid $primary;
	}
	.boxTxt {
		h1 {
			color: $primary;
			font-family: 'Roboto', sans-serif;
			font-weight: 700;
			text-transform: uppercase;
		}
		p {
			font-family: 'Roboto', sans-serif;
		}
	}
	.triangle-down {
		width: 100%;
    	height: 0;
    	padding-left: 50%;
    	padding-top: 25%;
    	overflow: hidden;
    	position: absolute;
    	div {
	 	  width: 0;
	      height: 0;
	      bottom: 0;
	      margin-left:-400px;
          margin-top: -200px;
		  border-left: 400px solid transparent;
          border-right: 400px solid transparent;
          border-top: 200px solid $primary;
          @media (max-width: 991px) {
	          display: none;
	      }
              &::before {
              	  content: " ";
				  position: absolute;
				  width: 0; 
	    		  height: 0;
	              margin-left:-396px;
	              margin-top: -200px;
	    		  border-left: 396px solid transparent;
	              border-right: 396px solid transparent;
	              border-top: 196px solid white;
	              @media (max-width: 991px) {
	              	display: none;
	              }
              }
    	}
	}
}

.box3 {
	border-top: 3px solid $primary;
	background: white;
	border-bottom: 3px solid $primary;
	.boxTxt {
		h1 {
			color: $primary;
			font-family: 'Roboto', sans-serif;
			font-weight: 700;
			text-transform: uppercase;
		}
		p {
			font-family: 'Roboto', sans-serif;
		}
	}
	.box3Img {
		background: url('../img/couple2.jpg') no-repeat;
		background-size: cover;
		background-position: 27% 50%;
		height: 300px;
	}
}

.introBox {
	background: #eaece9;
}

.banner2 {
	background: url(../img/banner2.jpg) no-repeat;
	background-position: 47%  50%;
	background-size: cover;
	height: 600px;
	position: relative;
	background-attachment: fixed;
	padding: 150px 0;
	@media (max-width: 1024px) {
		background-attachment: scroll;
	}
	@media (max-width: 991px) {
		height: auto;
	}
	h1 {
		z-index: 1;
		position: relative;
		padding: 30px 0;
		text-transform: uppercase;
		font-size: 3em;
	}

	h2 {
    	color: $wht;
    	font-size: 26px;
	}	

	&::before {
		background: url(../img/overlay.png) repeat;
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
	}
}

i.fa {
	color: white;
}


h1.title {
	color: $primary;
	text-transform: uppercase;
	padding: 20px 0;
}
a.btn {
	@include btn1;
}

.bg {
	background: #eaece9;
	h2 {
		color: $primary;
	}
	.picBox {
		@media (max-width: 600px) {
			width: 100%;
		}
	}
}
.padTopBtm {
	padding: 75px 15px;
}

nav.navbar {
	background: transparent;
	border: none;
	position: fixed;
	width: 100%;
	top: 0;
	z-index: 9999;
	transition: all 0.9s ease-in-out;
	&.navSolid {
		background: $primary;
	}
}

.logo {
	max-width: 175px;
	padding: 10px;
	width: 100%;

	@media (max-width: 767px) {
			max-width: 125px;
		}
}

.top-pad {
	margin-top: 115px;
}

.tech {
  background: #00cab0 !important;
}

.modal-open {
  overflow: auto; 
  padding-right: 0px !important;
  margin-right: 0px !important;
}